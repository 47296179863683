.main-layout-desktop {
  margin-top: 64px; //NOTE: Header height

  //NOTE: Margin left for Sidebar, keep for using later
  //&--content {
  //  margin-left: 80px;
  //}

  .main-layout-desktop--content--inner {
    min-height: calc(100vh - 64px); //NOTE: exclude Header height
  }
}
